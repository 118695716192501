import { Box, Text } from '@chakra-ui/layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './InfoColumn.module.scss';

export default function InfoColumn({
  icon, color, title, description,
}) {
  return (
    <Box className={styles.infoColumnContainer}>
      <FontAwesomeIcon size="2x" icon={icon} style={{ color }} />
      <Text as="h2" fontSize="2xl" className={styles.titleContainer}>{title}</Text>
      <Text as="p" fontSize="lg" className={styles.paragraphContainer}>
        {description}
      </Text>
    </Box>
  );
}

InfoColumn.propTypes = {
  icon: PropTypes.shape({
    iconName: PropTypes.string.isRequired,
    prefix: PropTypes.string.isRequired,
    icon: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.shape({})),
      ]),
    ).isRequired,
  }).isRequired,
  color: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};
