import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Badge, Box, Text } from '@chakra-ui/layout';

import { AnimatePresence, motion } from 'framer-motion';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal';
import { Button } from '@chakra-ui/button';
import * as styles from './InfoCard.module.scss';

export default function InfoCard({ data }) {
  const [isOpen, setIsOpen] = useState(false);
  const initialRef = React.useRef();
  const finalRef = React.useRef();

  const imageSource = getImage(data.image.localFile);

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={handleCloseModal}
        scrollBehavior="inside"
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{data.title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <GatsbyImage
              image={imageSource}
              alt={data.imageAltText}
              onClick={handleOpenModal}
              className={styles.modalImage}
            />
            <Text as="p" fontSize="lg" align="justify" className={styles.descriptionContainer}>
              {data.description}
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="teal" mr={3} onClick={handleCloseModal}>
              Inchide
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Box
        onClick={handleOpenModal}
        className={styles.imageContainer}
        ref={finalRef}
      >
        <GatsbyImage
          image={imageSource}
          alt={data.imageAltText}
          className={styles.imageCard}
        />

        <Box className={styles.distance}>
          <Badge>{`La ${data.distance} km`}</Badge>
        </Box>

        <Text
          key="image"
          className={styles.textOnImage}
          fontSize="2xl"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            duration: 0.15,
          }}
        >
          {data.title.toUpperCase()}
        </Text>
      </Box>
    </>
  );
}

InfoCard.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    imageAltText: PropTypes.string.isRequired,
    image: PropTypes.shape({
      localFile: PropTypes.shape({
        childImageSharp: PropTypes.shape({
          gatsbyImageData: PropTypes.shape({
            layout: PropTypes.string.isRequired,
            backgroundColor: PropTypes.string.isRequired,
            images: PropTypes.shape({
              fallback: PropTypes.shape({
                src: PropTypes.string.isRequired,
                srcSet: PropTypes.string.isRequired,
                sizes: PropTypes.string.isRequired,
              }).isRequired,
              sources: PropTypes.arrayOf(
                PropTypes.shape({
                  srcSet: PropTypes.string.isRequired,
                  type: PropTypes.string.isRequired,
                  sizes: PropTypes.string.isRequired,
                }).isRequired,
              ).isRequired,
            }).isRequired,
            width: PropTypes.number.isRequired,
            height: PropTypes.number.isRequired,
          }).isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
    distance: PropTypes.number.isRequired,
  }).isRequired,
};
