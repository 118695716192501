// extracted by mini-css-extract-plugin
export var big = "Presentation-module--big--3pQbz";
export var whitePresentationContainer = "Presentation-module--whitePresentationContainer--1Vov0";
export var grayPresentationContainer = "Presentation-module--grayPresentationContainer--3U9Im";
export var headerImage = "Presentation-module--headerImage--3Dz4S";
export var titleContainer = "Presentation-module--titleContainer--2NIVb";
export var dynamicFlex = "Presentation-module--dynamicFlex--1c4Sv";
export var contentWrapper = "Presentation-module--contentWrapper--3uUXS";
export var isLargerThan960 = "Presentation-module--isLargerThan960--2Qd1p";
export var isLargerThan768 = "Presentation-module--isLargerThan768--2EKUV";
export var isLargerThan550 = "Presentation-module--isLargerThan550--2_ahR";
export var isLessThan550 = "Presentation-module--isLessThan550--1VGHX";
export var isLessThan370 = "Presentation-module--isLessThan370--FXqQ8";