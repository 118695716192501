import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { Box, Flex, Text } from '@chakra-ui/layout';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import {
  faLeaf, faMountain, faRunning,
} from '@fortawesome/free-solid-svg-icons';

import InfoCard from 'components/InfoCard';

import { halfLorem, lorem } from 'shared/constants';

import InfoColumn from 'components/InfoColumn';
import DescriptionWithImage from 'components/DescriptionWithImage';
import * as styles from './Presentation.module.scss';

export default function Presentation() {
  const infoCardQuery = graphql`
  {
    allStrapiLandmarkCards {
      nodes {
        title
        description
        distance
        strapiId
        imageAltText
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  allStrapiInfoColumns {
    nodes {
      title
      description
      id
    }
  }
  allStrapiInfoRows {
      nodes {
        id
        title
        firstParagraph
        secondParagraph
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    allStrapiTitles {
    nodes {
      title
    }
  }
  }
`;

  const allData = useStaticQuery(infoCardQuery);

  const data = allData.allStrapiLandmarkCards.nodes;
  const infoColumns = allData.allStrapiInfoColumns.nodes;
  const infoRows = allData.allStrapiInfoRows.nodes;
  const titles = allData.allStrapiTitles.nodes;

  return (
    <div className={styles.big}>
      <Box className={styles.grayPresentationContainer}>
        <Box
          className={`${styles.contentWrapper} ${styles.dynamicFlex}`}
        >
          <InfoColumn icon={faRunning} color="23a6d5" title={infoColumns[0].title} description={infoColumns[0].description} />
          <InfoColumn icon={faLeaf} color="23d5ab" title={infoColumns[1].title} description={infoColumns[1].description} />
          <InfoColumn icon={faMountain} color="ee7752" title={infoColumns[2].title} description={infoColumns[2].description} />
        </Box>
      </Box>

      <Box className={styles.whitePresentationContainer}>
        {infoRows.map((row) => {
          const infoRowImage = getImage(row.image.localFile);
          return (
            <Box className={styles.contentWrapper}>
              <DescriptionWithImage
                key={row.id}
                title={row.title}
                firstParagraph={row.firstParagraph}
                secondParagraph={row.secondParagraph}
                image={infoRowImage}
              />
            </Box>
          );
        })}
      </Box>

      <Box className={styles.grayPresentationContainer}>
        <Box className={styles.contentWrapper}>
          <Text as="h1" fontSize="4xl" className={styles.titleContainer}>{titles[0].title}</Text>

          <Flex justify="center" align="center" flexWrap="wrap">
            {data.map((entry) => <InfoCard key={entry.strapiId} data={entry} />)}
          </Flex>
        </Box>
      </Box>
    </div>
  );
}
