import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/layout';
import { GatsbyImage } from 'gatsby-plugin-image';

import * as styles from './DescriptionWithImage.module.scss';

export default function DescriptionWithImage({
  title, firstParagraph, secondParagraph, image, right = true,
}) {
  return (
    <>
      <Text as="h1" fontSize="4xl" className={styles.titleContainer} align={right ? 'left' : 'right'}>{title}</Text>
      <Box
        className={styles.descriptionWithImageContainer}
      >

        {!right && (
          <GatsbyImage
            image={image}
            className={styles.image}
          />
        )}

        <Box className={styles.textContainer}>
          <Text as="p" fontSize="lg" className={styles.paragraphContainer}>
            {firstParagraph}
          </Text>

          <Text as="p" fontSize="lg" className={styles.paragraphContainer}>
            {secondParagraph}
          </Text>

        </Box>
        {right && (
          <GatsbyImage
            image={image}
            className={styles.image}
          />
        )}
      </Box>
    </>

  );
}
