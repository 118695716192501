import React from 'react';

import Layout from 'components/Layout';
import { Fade, Flex } from '@chakra-ui/react';
import Header from 'components/Header';
import Presentation from '../components/Presentation';

function IndexPage() {
  return (
    <Fade in>
      <Flex justify="center" align="center" style={{ backgroundColor: '#fffFFf' }}>
        <Presentation />
      </Flex>
    </Fade>
  );
}

export default IndexPage;
